import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieConsentBanner = ({ intl }) => (
  <CookieConsent
    location="bottom"
    buttonText={intl.formatMessage({ id: 'cookie-consent.accept-button' })}
    enableDeclineButton
    declineButtonText={intl.formatMessage({ id: 'cookie-consent.reject-button' })}
    style={{
      background: '#35a7ff',
      opacity: 0.85,
    }}
    contentStyle={{
      margin: '6px 10px',
      fontSize: '14px',
    }}
    buttonStyle={{
      backgroundColor: '#164e79',
      color: 'white',
      fontSize: '14px',
      margin: '10px',
    }}
    declineButtonStyle={{
      backgroundColor: '#164e79',
      color: 'white',
      margin: '10px',
      fontSize: '14px',
    }}
    expires={3650}
  >
    <FormattedMessage id="cookie-consent.line1" />
    <a href="https://www.cookiesandyou.com/" target="_blank" rel="noreferrer" className="p-l-sm has-text-light">
      <FormattedMessage id="cookie-consent.link" />
    </a>
  </CookieConsent>
);

CookieConsentBanner.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(CookieConsentBanner);
