import JSONRoutes from '../../conf/routes.json';

const removeTrailingSlashes = (path) => (path === '/' ? path : path.replace(/\/$/, ''));

export const localizedUrl = (path, locale = process.env.GATSBY_LANG || 'en') => {
  if (!path || path === '/') return path;

  const pathArray = path.split('/');
  pathArray.forEach((part, index, newPathArray) => {
    // eslint-disable-next-line no-param-reassign
    newPathArray[index] = JSONRoutes[part] ? (JSONRoutes[part][locale] || part) : part;
  });

  return removeTrailingSlashes(pathArray.join('/'));
};

export const getFullLocale = (locale = process.env.GATSBY_LANG || 'en') => {
  switch (locale) {
    case 'fr':
      return 'fr_FR';
    case 'es':
      return 'es_ES';
    case 'en':
    default:
      return 'en_US';
  }
};
