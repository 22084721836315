/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const NavLink = ({ activeTo, ...props }) => (
  <Link
    {...props}
    getProps={({ location }) => {
      let isActive = location.pathname.indexOf(activeTo || props.to) === 0;
      if (props.to === '/') isActive = location.pathname === props.to;
      return (
        isActive ? { className: `${props.className} ${props.activeClassName}` } : {});
    }}
  />
);

NavLink.propTypes = {
  activeTo: PropTypes.string,
};

NavLink.defaultProps = {
};

export default NavLink;
