import React, { useEffect, useState } from 'react';
import LogoSvg from '../images/logo.svg';
import BottleIconSvg from '../images/svg/icons/bottle-filled-label.svg';
import JamIconSvg from '../images/svg/icons/jam-filled-label.svg';
import MiscIconSvg from '../images/svg/icons/misc-filled-label.svg';
import PresentIconSvg from '../images/svg/icons/present-filled-label.svg';
import PreserveIconSvg from '../images/svg/icons/preserve-filled-label.svg';
import SchoolIconSvg from '../images/svg/icons/school-filled-label.svg';
import TextLogoSvg from '../images/text-logo.svg';
import { localizedUrl } from '../utils/js/locale-utils';
import headerStyles from './header.module.scss';
import NavLink from './navlink';

const Header = () => {
  const [scrollPosition, setSrollPosition] = useState(0);
  const [isMenuActive, setMenuActive] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setSrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function variablePosition(offset, multiplier) {
    let variable = scrollPosition * multiplier;
    if (variable > 200) variable = 200;
    if (variable < -100) variable = -100;

    return offset + variable;
  }

  return (
    <header className={`hero is-primary is-medium ${headerStyles.header}`}>
      <div className="hero-head">
        <nav className="navbar">
          <div className="container">
            <div className="navbar-brand">
              <a href="/" className={`navbar-item is-hidden-desktop ${isMenuActive ? '' : 'is-hidden'}`}>
                <img src={LogoSvg} alt="Logo" />
              </a>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                role="button"
                tabIndex="0"
                className={`navbar-burger has-text-white ${isMenuActive ? 'is-active' : ''}`}
                data-target="navMenu"
                aria-label="menu"
                aria-expanded="false"
                onClick={() => { setMenuActive(!isMenuActive); }}
                onKeyDown={() => { setMenuActive(!isMenuActive); }}
              >
                <span />
                <span />
                <span />
              </a>
            </div>
            <div id="navbarMenu" className={`navbar-menu ${isMenuActive ? 'is-active m-b-md' : ''}`}>
              <div className="navbar-end">
                <NavLink to="/" className="navbar-item" activeClassName="is-active">
                  <span className="icon p-r-sm">
                    <i className="fas fa-home" />
                  </span>
                  <span>Accueil</span>
                </NavLink>
                <NavLink to={localizedUrl('/news')} className="navbar-item" activeClassName="is-active">
                  <span className="icon p-r-sm">
                    <i className="far fa-newspaper" />
                  </span>
                  <span>Actualités</span>
                </NavLink>
                <NavLink to={localizedUrl('/help')} className="navbar-item" activeClassName="is-active">
                  <span className="icon p-r-sm">
                    <i className="far fa-question-circle" />
                  </span>
                  <span>Aide</span>
                </NavLink>
                <NavLink to={localizedUrl('/guestbook')} className="navbar-item" activeClassName="is-active">
                  <span className="icon p-r-sm">
                    <i className="fas fa-book" />
                  </span>
                  <span>Livre d&apos;or</span>
                </NavLink>
                <NavLink to={localizedUrl('/contact')} className="navbar-item" activeClassName="is-active">
                  <span className="icon p-r-sm">
                    <i className="far fa-envelope" />
                  </span>
                  <span>Contact</span>
                </NavLink>
                <span className="navbar-item">
                  <a href="https://www.facebook.com/MesEtiquettes/" target="_blank" rel="noopener noreferrer" className="has-text-white is-4">
                    <span className="icon">
                      <i className="fab fa-facebook-square fa-lg" />
                    </span>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div className="hero-body">
        <div className="container has-text-left">
          <NavLink to="/">
            <img src={TextLogoSvg} className={`${headerStyles.textLogo} is-hidden-mobile`} alt="Mes Etiquettes" />
            <img src={LogoSvg} className={`${headerStyles.textLogoMobile} is-hidden-tablet`} alt="Mes Etiquettes" />
          </NavLink>

          <h2 className={`${headerStyles.subtitle} m-t-xs`}>
            Créez vos étiquettes personnalisées en quelques clics !
          </h2>

          <div
            className={`${headerStyles.deco} ${headerStyles.decoVerticalBig} ${headerStyles.decoInfo} ${headerStyles.decoLeft2}`}
            style={{ top: `${variablePosition(60, 0.3)}px` }}
          />
          <div
            className={`${headerStyles.deco} ${headerStyles.decoHorizontalSmall} ${headerStyles.decoWarning} ${headerStyles.decoLeft1}`}
            style={{ top: `${variablePosition(140, 0.1)}px` }}
          />

          <div
            className={`${headerStyles.deco} ${headerStyles.decoVerticalBig} ${headerStyles.decoInfo} ${headerStyles.decoLeft5}
            is-hidden-mobile`}
            style={{ top: `${variablePosition(40, 0.15)}px` }}
          />
          <div
            className={`${headerStyles.deco} ${headerStyles.decoHorizontalBig} ${headerStyles.decoWarning} ${headerStyles.decoLeft4}
            is-hidden-mobile`}
            style={{ top: `${variablePosition(6, 0.2)}px` }}
          />
          <div
            className={`${headerStyles.deco} ${headerStyles.decoHorizontalSmall} ${headerStyles.decoDanger} ${headerStyles.decoLeft3}
            is-hidden-mobile`}
            style={{ top: `${variablePosition(28, -0.1)}px` }}
          />

          <div
            className={`${headerStyles.deco} ${headerStyles.decoHorizontalBig} ${headerStyles.decoDanger} ${headerStyles.decoLeft7}
            is-hidden-touch is-hidden-mobile-only is-hidden-tablet-only is-hidden-desktop-only is-hidden-widescreen-only`}
            style={{ top: `${variablePosition(80, 0.3)}px` }}
          />
          <div
            className={`${headerStyles.deco} ${headerStyles.decoVerticalSmall} ${headerStyles.decoWarning} ${headerStyles.decoLeft6}
            is-hidden-touch is-hidden-mobile-only is-hidden-tablet-only is-hidden-desktop-only is-hidden-widescreen-only`}
            style={{ top: `${variablePosition(120, 0.15)}px` }}
          />

          <div
            className={`${headerStyles.deco} ${headerStyles.decoHorizontalSmall} ${headerStyles.decoInfo}
            ${headerStyles.decoRight3} is-hidden-touch`}
            style={{ top: `${variablePosition(180, -0.1)}px` }}
          />
          <div
            className={`${headerStyles.deco} ${headerStyles.decoVerticalBig} ${headerStyles.decoWarning}
            ${headerStyles.decoRight2} is-hidden-touch`}
            style={{ top: `${variablePosition(90, 0.2)}px` }}
          />
          <div
            className={`${headerStyles.deco} ${headerStyles.decoHorizontalBig} ${headerStyles.decoDanger}
            ${headerStyles.decoRight1} is-hidden-touch`}
            style={{ top: `${variablePosition(40, 0.3)}px` }}
          />

        </div>
      </div>

      <div className="hero-foot">
        <nav className={`tabs ${headerStyles.navtabs}`}>
          <div className="container">
            <ul className={headerStyles.navul}>
              <li className={`${headerStyles.navli}`}>
                <NavLink
                  to={localizedUrl('/jam/create/shape')}
                  activeTo={localizedUrl('/jam/create')}
                  className={`${headerStyles.navlink} ${headerStyles.jam}`}
                  activeClassName={headerStyles.isActive}
                >
                  <span className={`${headerStyles.navicon}`}>
                    <JamIconSvg />
                  </span>
                  <span className={headerStyles.navtext}>Confiture</span>
                </NavLink>
              </li>
              <li className={`${headerStyles.navli}`}>
                <NavLink
                  to={localizedUrl('/preserve/create/shape')}
                  activeTo={localizedUrl('/preserve/create')}
                  className={`${headerStyles.navlink} ${headerStyles.preserve}`}
                  activeClassName={headerStyles.isActive}
                >
                  <span className={`${headerStyles.navicon}`}>
                    <PreserveIconSvg />
                  </span>
                  <span className={`${headerStyles.navtext} p-l-xs`}>Conserve</span>
                </NavLink>
              </li>
              <li className={`${headerStyles.navli}`}>
                <NavLink
                  to={localizedUrl('/bottle/create/shape')}
                  activeTo={localizedUrl('/bottle/create')}
                  className={`${headerStyles.navlink} ${headerStyles.bottle}`}
                  activeClassName={headerStyles.isActive}
                >
                  <span className={`${headerStyles.navicon}`}>
                    <BottleIconSvg />
                  </span>
                  <span className={headerStyles.navtext}>Bouteille</span>
                </NavLink>
              </li>
              <li className={`${headerStyles.navli}`}>
                <NavLink
                  to={localizedUrl('/school/create/shape')}
                  activeTo={localizedUrl('/school/create')}
                  className={`${headerStyles.navlink} ${headerStyles.school}`}
                  activeClassName={headerStyles.isActive}
                >
                  <span className={`${headerStyles.navicon}`}>
                    <SchoolIconSvg />
                  </span>
                  <span className={headerStyles.navtext}>Scolaire</span>
                </NavLink>
              </li>
              <li className={`${headerStyles.navli}`}>
                <NavLink
                  to={localizedUrl('/gift/create/shape')}
                  activeTo={localizedUrl('/gift/create')}
                  className={`${headerStyles.navlink} ${headerStyles.present}`}
                  activeClassName={headerStyles.isActive}
                >
                  <span className={`${headerStyles.navicon}`}>
                    <PresentIconSvg />
                  </span>
                  <span className={headerStyles.navtext}>Cadeaux</span>
                </NavLink>
              </li>
              <li className={`${headerStyles.navli}`}>
                <NavLink
                  to={localizedUrl('/create/shape')}
                  activeTo={localizedUrl('/create')}
                  className={`${headerStyles.navlink} ${headerStyles.misc}`}
                  activeClassName={headerStyles.isActive}
                >
                  <span className={`${headerStyles.navicon}`}>
                    <MiscIconSvg />
                  </span>
                  <span className={headerStyles.navtext}>Autre/Divers</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
        <div className={headerStyles.navbottom} />
      </div>
    </header>
  );
};

Header.propTypes = {
};

Header.defaultProps = {
};

export default Header;
