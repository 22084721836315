import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { loadDayjs } from '../utils/js/date';
import CookieConsentBanner from './cookie-consent-banner';
import Footer from './footer';
import Header from './header';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  loadDayjs(process.env.GATSBY_LANG);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className="has-background-white">
        {children}
      </main>
      <Footer />
      <CookieConsentBanner />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
