import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import AdFallback from './ad-fallback';

const GoogleAdsense = ({
  className, style, client, slot, format, responsive, layout, layoutKey, small,
}) => {
  const adRef = useRef();
  const [showFallback, setShowFallback] = useState(false);

  function checkAds() {
    if (adRef?.current?.children) {
      setShowFallback(adRef?.current?.children.length < 1);
    }
  }

  useEffect(() => {
    if (window) {
      setTimeout(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }, 0);
    }

    setTimeout(() => {
      checkAds();
    }, 3000);
  }, []);

  return (
    <>
      <ins
        ref={adRef}
        className={`${className || ''} adsbygoogle`}
        style={style}
        data-ad-client={client}
        data-ad-slot={slot}
        data-ad-layout={layout}
        data-ad-layout-key={layoutKey}
        data-ad-format={format}
        data-full-width-responsive={responsive}
      />
      { showFallback ? <AdFallback small={small} /> : ''}
    </>
  );
};

GoogleAdsense.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  client: PropTypes.string.isRequired,
  slot: PropTypes.string.isRequired,
  layout: PropTypes.string,
  layoutKey: PropTypes.string,
  format: PropTypes.string,
  responsive: PropTypes.string,
  small: PropTypes.bool,
};

export default GoogleAdsense;
