import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import adFallbackStyles from './ad-fallback.module.scss';
import LabelMakerComponents from './label-maker/label-maker-components';

const AdFallback = ({
  className,
  small,
}) => (
  <div
    className={`${className || ''} ${adFallbackStyles.adFallback} ${small ? adFallbackStyles.noBorder : ''} has-text-left`}
    style={{
      backgroundImage: `url(${LabelMakerComponents.shape.octogone})`,
    }}
  >
    <p>
      <FormattedMessage id="ad-fallback.ads-help-website" />
      <br />
      <b><FormattedMessage id="ad-fallback.disable-ads-blocker" /></b>
    </p>
    { small ? '' : (
      <p className="m-t-sm">
        <FormattedMessage id="ad-fallback.support-website" />
      </p>
    )}
    {/* TODO: Manage this link for EN locale */}
    <form className="has-text-centered m-t-sm" action="https://www.paypal.com/donate" method="post" target="_blank">
      <input type="hidden" name="hosted_button_id" value="UTZDWVXY6FGBJ" />
      <input type="image" src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_donateCC_LG.gif" border="0" name="submit" alt="Faire un don" />
      <img alt="" border="0" src="https://www.paypal.com/fr_FR/i/scr/pixel.gif" width="1" height="1" />
    </form>
  </div>
);

AdFallback.propTypes = {
  className: PropTypes.string,
  small: PropTypes.bool,
};

export default injectIntl(AdFallback);
