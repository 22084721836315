/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable array-callback-return */
import JSONData from '../../conf/label-maker.json';

const LabelMakerComponents = {
  shape: {},
  image: {
    loading: require('../../images/svg/loading.svg'),
  },
};

// Load svg shapes
// Use gatsby-plugin-react-svg for inline svg
JSONData.shapes.map((shape) => {
  LabelMakerComponents.shape[shape] = require(`../../images/svg/shapes/${shape}.svg`);
});

// Load illustrations
Object.keys(JSONData.images).map((category) => {
  JSONData.images[category].map((image) => {
    LabelMakerComponents.image[image] = require(`../../images/svg/illustrations/${category}/${image}.svg`);
  });
});

export default LabelMakerComponents;
