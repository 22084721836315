import { Link } from 'gatsby';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import React from 'react';
import LogoSvg from '../images/logo.svg';
import { localizedUrl } from '../utils/js/locale-utils';
import GoogleAdsense from './google-adsense';

const Footer = () => (
  <footer className="footer is-primary has-text-white">
    <div className="container">
      <div className="columns">
        <div className="column is-one-fifth">
          <b><FormattedMessage id="footer.categories" /></b>
          <ul className="is-size-6 m-t-sm">
            <li>
              <Link to={localizedUrl('/jam/create/shape')} className="has-text-primary-light">
                <FormattedMessage id="footer.jam" />
              </Link>
            </li>
            <li>
              <Link to={localizedUrl('/preserve/create/shape')} className="has-text-primary-light">
                <FormattedMessage id="footer.preserve" />
              </Link>
            </li>
            <li>
              <Link to={localizedUrl('/bottle/create/shape')} className="has-text-primary-light">
                <FormattedMessage id="footer.bottle" />
              </Link>
            </li>
            <li>
              <Link to={localizedUrl('/school/create/shape')} className="has-text-primary-light">
                <FormattedMessage id="footer.school" />
              </Link>
            </li>
            <li>
              <Link to={localizedUrl('/gift/create/shape')} className="has-text-primary-light">
                <FormattedMessage id="footer.gift" />
              </Link>
            </li>
            <li>
              <Link to={localizedUrl('/create/shape')} className="has-text-primary-light">
                <FormattedMessage id="footer.other" />
              </Link>
            </li>
          </ul>
        </div>
        <div className="column is-one-fifth">
          <b><FormattedMessage id="footer.sitename" /></b>
          <ul className="is-size-6 m-t-sm">
            <li>
              <Link to={localizedUrl('/news')} className="has-text-primary-light">
                <FormattedMessage id="footer.news" />
              </Link>
            </li>
            <li>
              <Link to={localizedUrl('/help')} className="has-text-primary-light">
                <FormattedMessage id="footer.help" />
              </Link>
            </li>
            <li>
              <Link to={localizedUrl('/guestbook')} className="has-text-primary-light">
                <FormattedMessage id="footer.guestbook" />
              </Link>
            </li>
            <li>
              <Link to={localizedUrl('/contact')} className="has-text-primary-light">
                <FormattedMessage id="footer.contact" />
              </Link>
            </li>
          </ul>
        </div>
        <div className="column is-one-fifth">
          <b><FormattedMessage id="footer.social-network" /></b>
          <ul className="is-size-6 m-t-sm">
            <li>
              <a href="https://www.facebook.com/MesEtiquettes/" target="_blank" rel="noopener noreferrer" className="has-text-primary-light">
                <FormattedMessage id="footer.facebook" />
              </a>
            </li>
          </ul>
        </div>
        <div className="column is-one-fifth">
          <p>
            <b><FormattedMessage id="footer.partners" /></b>
          </p>
          <div className="m-t-sm" style={{ maxWidth: '220px', maxHeight: '200px' }}>
            <GoogleAdsense
              style={{ display: 'block' }}
              client="ca-pub-1569243764016253"
              slot="5934052065"
              format="link"
              responsive="true"
              small
            />
          </div>
        </div>
        <div className="column is-one-fifth">
          <Link to="/">
            <img src={LogoSvg} alt="Logo" />
          </Link>
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
};

Footer.defaultProps = {
};

export default injectIntl(Footer);
